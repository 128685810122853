import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { FaCalendarAlt, FaUser } from "react-icons/fa"

import { kebabCase } from 'lodash'
const _ = require('lodash')

class CategoryRoute extends React.Component {
  render() {
    const posts = this.props.data.current.edges
    const allposts = this.props.data.all.edges
    
    let categories = []
    allposts.forEach(edge => {
      if (_.get(edge, `node.frontmatter.category`)) {
        categories = categories.concat(edge.node.frontmatter.category)
      }
    })
    categories = _.uniq(categories)

    let archives = []
    allposts.forEach(edge => {
      if (_.get(edge, `node.frontmatter.date`)) {
        archives = archives.concat(edge.node.frontmatter.date.substring(3))
      }
    })
    archives = _.uniq(archives)

    const postLinks = posts.map(post => (
      <li key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <h4 className="title is-size-4 has-text-weight-bold is-bold-light hover-color" style={{marginBottom: 0}}>
            {post.node.frontmatter.title}
          </h4>
          <p className="post-date">
            <FaCalendarAlt />
            <span>
              {post.node.frontmatter.date}
            </span>
            &nbsp;&nbsp;
            <FaUser />
            <span>
              By {post.node.frontmatter.author}
            </span>
            &nbsp;&nbsp;
          </p>
        </Link>
      </li>
    ))
    const category = this.props.pageContext.category
    const title = this.props.data.site.siteMetadata.title
    const categoryHeader = `Posts in “${category}”`

    
    return (
      <Layout>
        <section className="section" >
          <Helmet title={`${category} | ${title}`} />
          <div className="container content" style={{ marginTop: 100}}>
            <div className="columns">
              <div className="column is-9">
                <h3 className="title is-size-4 is-bold-light">{categoryHeader}</h3>
                <ul className="taglist">{postLinks}</ul>
              </div>
              <div className="column is-3">
                <h4>CATEGORIES</h4>
                <div>
                  {
                    categories.map((item, i)=>{
                      return(
                        <div className="category">
                          <a href={`/categories/${kebabCase(item)}/`}>
                            {item}
                          </a>
                        </div>
                      )
                    })
                  }
                </div>
                <h4 style={{margin: "2rem 0 0"}}>META</h4>
                <div className="category">
                  <a href="/admin">Login</a>
                </div>
                <h4 style={{margin: "2rem 0 0"}}>ARCHIVES</h4>
                <div>
                  {
                    archives.map((item, i)=>{
                      return(
                        <div className="category">
                          <a href={`/archives/${kebabCase(item)}/`}>
                            {item}
                          </a>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default CategoryRoute

export const categoryPageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    current: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: {in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
            author
            category
          }
        }
      }
    }
    all: allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]},
      filter: {frontmatter: {templateKey: {eq: "blog-post"}}}
    ){
      edges{
        node{
          id
          fields{
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "DD MMMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author
            category
          }
        }
      }
    }
  }
`
